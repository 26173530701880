import { createSlice } from "@reduxjs/toolkit";
import { IMarathon, SliceState } from "entities/admin/lib/types";
import { deleteStatusComment } from "../thunk/deleteStatusComment";
import { getDisabledPeople } from "../thunk/getDisabledPeople";
import { adminGetMarathon } from "../thunk/getMarathonAdminCreateThunk";
import { getMarathonById } from "../thunk/getMarathonById";
import { getMarathons } from "../thunk/getMarathons";
import { getStatusComments } from "../thunk/getStatusComments";
import { getUserById } from "../thunk/getUserById";
import { getUsers } from "../thunk/getUsers";
import { getVouchers } from "../thunk/getVouchers";
import { postStatusComments } from "../thunk/postStatusComments";
import { patchUserStatus } from "../thunk/patchUserStatus";
import { getUserInfoByStarterKitId } from "../thunk/getUserInfoByStarterKitId";
import {
  createMarathon,
  getParticipantInfo,
  updateMarathon,
} from "../thunk/createMarathon";
import { getApplicationsByMarathonId } from "../thunk/getApplicationsByMarathonId";
import { getParticipantInfoBySearchName } from "../thunk/getParticipantInfoBySearchName";
import { editParticipantProfileByAdmin } from "../thunk/editParticipantProfileByAdmin";
import { getMarathonByIdForAdmin } from "../thunk/getMarathonByIdForAdmin";
import { createVoucher } from "../thunk/createVoucher";
import { addPromocodesToVouchers } from "../thunk/addPromocodesToVouchers";
import { updateVoucher } from "../thunk/EditVoucherName";
import { deleteNonActivatedPromocodes } from "../thunk/deleteNonActivatedPromocodes";
import { getVoucherPromocodesByVoucherId } from "../thunk/getVoucherPromocodesByVoucherId";
import { deleteVoucherPromocodesById } from "../thunk/deleteVoucherPromocodesById";
import { deleteVoucherById } from "../thunk/deleteVoucherById";
import { getParticipantInfoBySearch } from "../thunk/getParticipantInfoBySearch";
import { createUser } from "../thunk/createUser";
import { getAdminsAndVolunteers } from "../thunk/getAdminsAndVolunteers";
import { deleteUserByOwner } from "../thunk/deleteUserByOwner";
import { deleteMarathon } from "../thunk/deleteMarathon";
import { getMarathonResultsByAdmin } from "../thunk/getMarathonResultsByAdmin";
import { getUserInfoByStartNumber } from "../thunk/getUserInfoByStartNumber";
import { getMarathonResultsBySearch } from "../thunk/getMarathonResultsBySearch";
import { getCorporateClientsThunk } from "../thunk/getCorporateClientsThunk";
import { getVerifiedUsers } from "../thunk/getVerifiedUsersForVoucher";
import { getCorporateClientsByMarathonIdThunk } from "../thunk/getCorporateClientsByMarathonId";
import { getUserPromocodes } from "../thunk/getUserPromocodes";
import { getUserInsurance } from "../thunk/getUserInsurance";
import { getCorporateIssueByMarathonIdAndClientId } from "../thunk/getCorporateIssueByMarathonIdAndClientId";
import { getCorporateOraganizationsInfoBySearch } from "../thunk/getCorporateOrganizationInfoBySearch";
import { getCorporateIssueByMarathonIdAndClientIdSearch } from "../thunk/getCorporateIssueByMarathonIdAndClientIdSearch";
import { getMarathonDistances } from "../thunk/getMarathonDistances";

const initialState: SliceState = {
  startDateAcceptingApplications: null,
  endDateAcceptingApplications: null,
  participantNumbersPrintDate: null,
  //participantsList
  distances: [],
  participantsListMarathonName: null,
  page: 1,
  pageSize: 10,
  gender: "",
  isShowFilter: false,
  specificDistance: "",
  age: "",
  country: null,
  countryIndex: null,
  payment: "",
  participantType: "",
  startPackage: "",
  participantStatus: "",
  //createVoucher
  createVoucherLoading: false,
  getVouchersListLoading: false,
  addPromocodesToVouchersLoading: false,
  addPromocodesToVouchersError: null,
  editVoucherLoading: false,
  deleteNonActivatedPromocodesLoading: false,
  getVoucherPromocodesByVoucherIdLoading: false,
  voucherPromocodes: [],
  voucherPromocodesAmount: null,
  deleteVoucherPromocodesByIdLoading: false,
  voucherName: "",
  startKitIssueType: 0,
  deleteVoucherByIdLoading: false,
  marathonId: null,
  isVoucherActive: false,
  corporateClients: null,
  corporateClient: null,
  isGetCorporateClientLoading: false,

  //
  usersFilters: {
    passport: null,
    marathonName: null,
    searchName: "",
    verify: null,
  },

  //createMarathon
  createMarathonRequestLoading: false,

  //updateMarathon
  updateMarathonRequestLoading: false,

  //deleteMarathon
  deleteMarathonLoading: false,

  //createUser
  createUserLoading: false,
  createUserEmailError: null,
  adminsAndVolunteers: [],
  getAdminsAndVolunteersLoading: false,
  getAdminsAndVolunteersError: null,
  deleteUserByOwnerLoading: [],

  //participant Info
  participantInfo: null,
  isGetParticipantInfoLoading: false,
  participantNotFound: false,
  getParticipantInfoError: null,

  //editParticipantByAdmin
  participantEditLoading: false,

  //getUserPromocodes
  userPromocodes: [],
  userPromocodesLoading: false,
  userPromocodesError: null,

  //participantsList
  participantsList: [],
  participantsCount: null,
  participantsChangeDistanceCount: null,
  getParticipantsListLoading: false,
  getParticipantsListError: null,

  //getCorporateIssueByMarathonIdAndClientId
  corporateIssueByMarathonIdAndClientIdLoading: false,
  corporateIssueByMarathonIdAndClientId: null,
  corporateIssueByMarathonIdAndClientIdTotalCount: null,
  corporateIssueByMarathonIdAndClientIdChangeDistanceCount: null,
  corporateIssueByMarathonIdAndClientIdError: null,

  hasUserInsurance: false,
  hasUserInsuranceLoading: false,
  hasUserInsuranceError: null,

  marathonForAdmin: null,
  marathon: null,
  marathons: [],
  activeMarathons: [],
  extendedMarathon: null,
  getMarathonByIdLoading: false,
  vouchers: [],
  users: null,
  isGetUsersLoading: false,
  isGetDisabledPeopleLoading: false,
  usersQuery: {
    verify: "%2Cstatus%21%3D%20null%2Cstatus.currentStatus%21%3DConfirmed",
    dateEvent: "",
    participationStatus: "",
    documents: "",
    userStatus: "",
    searchName: "",
  },
  disabledPeople: null,
  disabledPeopleQuery: {
    verify: "%2Cstatus%21%3D%20null%2Cstatus.currentStatus%21%3DConfirmed",
    dateEvent: "",
    documents: "",
    searchName: "",
    marathonName: "",
  },
  user: null,
  comments: null,
  userStatusSuccess: false,
  userStatusSuccessLoading: false,
  isCreateCommentLoading: false,
  isDeleteCommentLoading: false,
  isSendCommentLoading: false,
  commentsSuccess: true,
  userInfo: null,
  getUserByIdLoading: false,
  //marathon results for admin
  marathonResults: [],
  marathonResultsDistances: [],
  isGetMarathonResultsLoading: false,
  marathonResultsCount: null,
  isGetMarathonResultsBySearchLoading: false,

  //corporateClientsByMarathonId
  corporateClientsByMarathonIdTotalCount: null,
  corporateClientsByMarathonId: [],
  corporateClientsByMarathonIdLoading: false,
  map: function (
    arg0: (item: { name: string; id: any }) => { name: string; id: any }
  ): unknown {
    throw new Error("Function not implemented.");
  },
  unreadNotificationsCount: 0,
  allUsersNotificationsCount: 0,
  disabledUsersNotificationsCount: 0,
  marathonDistances: null,
  marathonDistancesLoading: false,
  marathonDistancesError: null,
};

const marathonSlice = createSlice({
  name: "marathon",
  initialState,
  reducers: {
    setUnreadNotificationsCount: (state, action) => {
      state.unreadNotificationsCount = action.payload;
    },
    setAllUsersNotificationsCount: (state, action) => {
      state.allUsersNotificationsCount = action.payload;
    },
    setDisabledUsersNotificationsCount: (state, action) => {
      state.disabledUsersNotificationsCount = action.payload;
    },
    //participantsList
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setIsShowFilter: (state, action) => {
      state.isShowFilter = action.payload;
    },
    setSpecificDistance: (state, action) => {
      state.specificDistance = action.payload;
    },
    setAge: (state, action) => {
      state.age = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setCountryIndex: (state, action) => {
      state.countryIndex = action.payload;
    },
    setPayment: (state, action) => {
      state.payment = action.payload;
    },
    setParticipantType: (state, action) => {
      state.participantType = action.payload;
    },
    setStartPackage: (state, action) => {
      state.startPackage = action.payload;
    },
    setParticipantStatus: (state, action) => {
      state.participantStatus = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    //CREATING MARATHON
    setStartDateAcceptingApplications: (state, action) => {
      state.startDateAcceptingApplications = action.payload;
    },
    setEndDateAcceptingApplications: (state, action) => {
      state.endDateAcceptingApplications = action.payload;
    },
    setParticipantNumbersPrintDate: (state, action) => {
      state.participantNumbersPrintDate = action.payload;
    },
    //USERS
    setUsersQuery: (state, action) => {
      state.usersQuery[action.payload.key] = action.payload.query;
    },
    setUsersFilters: (state, action) => {
      state.usersFilters = { ...state.usersFilters, ...action.payload };
    },
    setClearUsersQuery: (state, action) => {
      state.usersQuery = {
        verify: action.payload,
        dateEvent: "",
        documents: "",
        searchName: "",
        marathonName: "",
      };
    },
    setDisabledPeopleQuery: (state, action) => {
      state.disabledPeopleQuery[action.payload.key] = action.payload.query;
    },
    clearUserInfo: (state) => {
      state.userInfo = null;
    },
    clearExtendedMarathon: (state) => {
      state.extendedMarathon = null;
    },
    clearServerError: (state) => {
      state.addPromocodesToVouchersError = null;
    },
    updateVoucherActivation: (state, action) => {
      const specificMarathonIndex = state.vouchers.findIndex(
        (marathon) => marathon?.id === action.payload?.clickedMarathonInfo.id
      );
      const specificVoucherIndex = state.vouchers[
        specificMarathonIndex
      ].vouchers.findIndex(
        (voucher) => voucher?.id === action.payload?.voucherData.id
      );

      if (specificMarathonIndex !== -1 && specificVoucherIndex !== -1) {
        state.vouchers[specificMarathonIndex].vouchers[
          specificVoucherIndex
        ].isActive = action.payload?.voucherData.isActive;
      }
    },
    updateVoucherName: (state, action) => {
      const specificMarathonIndex = state.vouchers.findIndex(
        (marathon) => marathon?.id === action.payload?.clickedMarathonInfo.id
      );
      const specificVoucherIndex = state.vouchers[
        specificMarathonIndex
      ]?.vouchers.findIndex(
        (voucher) => voucher?.id === action.payload?.voucherData.id
      );

      if (specificMarathonIndex !== -1 && specificVoucherIndex !== -1) {
        state.vouchers[specificMarathonIndex].vouchers[
          specificVoucherIndex
        ].name = action.payload?.voucherData.name;
      }
    },
    voucherPromocodeCheckedHandler: (state, action) => {
      state.voucherPromocodes = state.voucherPromocodes.map(
        (voucherPromocode) =>
          voucherPromocode.id === action.payload.id
            ? { ...voucherPromocode, checked: action.payload.checked }
            : voucherPromocode
      );
    },
    clearErrors: (state) => {
      state.createUserEmailError = null;
    },
    deleteUserLocally: (state, action) => {
      state.adminsAndVolunteers = state.adminsAndVolunteers.filter(
        (user: any) => user.id !== action.payload
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(adminGetMarathon.fulfilled, (state, action) => {
        state.marathon = action.payload;
      })
      .addCase(getMarathons.fulfilled, (state, action) => {
        state.marathons = action.payload.query;
        state.activeMarathons = action.payload.query.filter((i: IMarathon) => {
          let startDate = new Date(i.startDateAcceptingApplications.toString());
          let endDate = new Date(i.endDateAcceptingApplications.toString());
          let currentDate = new Date();

          return startDate <= currentDate && endDate >= currentDate;
        });
      })
      .addCase(getVouchers.pending, (state) => {
        state.getVouchersListLoading = true;
      })
      .addCase(getVouchers.fulfilled, (state, { payload }) => {
        state.vouchers = payload.query;
        state.voucherPromocodesAmount = payload.count;
        state.getVouchersListLoading = false;
      })
      .addCase(getVouchers.rejected, (state) => {
        state.getVouchersListLoading = false;
      })

      //USERS
      .addCase(getStatusComments.fulfilled, (state, action) => {
        state.comments = action.payload;
      })
      .addCase(getVerifiedUsers.pending, (state) => {
        state.isGetUsersLoading = true;
      })
      .addCase(getVerifiedUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isGetUsersLoading = false;
      })
      .addCase(getVerifiedUsers.rejected, (state) => {
        state.isGetUsersLoading = false;
      })
      .addCase(getUsers.pending, (state) => {
        state.isGetUsersLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isGetUsersLoading = false;
      })
      .addCase(getUsers.rejected, (state) => {
        state.isGetUsersLoading = false;
      })
      .addCase(getDisabledPeople.pending, (state) => {
        state.isGetDisabledPeopleLoading = true;
      })
      .addCase(getDisabledPeople.fulfilled, (state, action) => {
        state.disabledPeople = action.payload;
        state.isGetDisabledPeopleLoading = false;
      })
      .addCase(getDisabledPeople.rejected, (state) => {
        state.isGetDisabledPeopleLoading = false;
      })
      .addCase(getUserById.pending, (state) => {
        state.getUserByIdLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.user = action.payload;
        state.getUserByIdLoading = false;
      })
      .addCase(getUserById.rejected, (state) => {
        state.getUserByIdLoading = false;
      })
      .addCase(patchUserStatus.pending, (state, action) => {
        state.userStatusSuccessLoading =
          action.meta.arg.commentsId.length === 0;
        state.isSendCommentLoading = action.meta.arg.commentsId.length > 0;
      })
      .addCase(patchUserStatus.fulfilled, (state) => {
        state.userStatusSuccess = !state.userStatusSuccess;
        state.userStatusSuccessLoading = false;
        state.isSendCommentLoading = false;
      })
      .addCase(patchUserStatus.rejected, (state) => {
        state.userStatusSuccess = false;
        state.userStatusSuccessLoading = false;
        state.isSendCommentLoading = false;
      })
      .addCase(deleteStatusComment.pending, (state) => {
        state.isDeleteCommentLoading = true;
      })
      .addCase(deleteStatusComment.fulfilled, (state, _) => {
        state.commentsSuccess = !state.commentsSuccess;
        state.isDeleteCommentLoading = false;
      })
      .addCase(deleteStatusComment.rejected, (state, _) => {
        state.commentsSuccess = false;
        state.isDeleteCommentLoading = true;
      })
      .addCase(postStatusComments.pending, (state) => {
        state.isCreateCommentLoading = true;
      })
      .addCase(postStatusComments.fulfilled, (state) => {
        state.commentsSuccess = !state.commentsSuccess;
        state.isCreateCommentLoading = false;
      })

      .addCase(postStatusComments.rejected, (state) => {
        state.commentsSuccess = false;
        state.isCreateCommentLoading = false;
      })
      .addCase(getMarathonById.pending, (state) => {
        state.getMarathonByIdLoading = true;
      })
      .addCase(getMarathonById.fulfilled, (state, { payload }) => {
        if (payload) {
          state.extendedMarathon = payload;
          state.participantsListMarathonName = payload.name;
          state.distances = payload.distances;
        }
        state.getMarathonByIdLoading = false;
      })
      .addCase(getMarathonById.rejected, (state) => {
        state.getMarathonByIdLoading = false;
      })
      .addCase(createMarathon.pending, (state) => {
        state.createMarathonRequestLoading = true;
      })
      .addCase(createMarathon.fulfilled, (state) => {
        state.createMarathonRequestLoading = false;
      })
      .addCase(createMarathon.rejected, (state) => {
        state.createMarathonRequestLoading = false;
      })
      .addCase(updateMarathon.pending, (state) => {
        state.updateMarathonRequestLoading = true;
      })
      .addCase(updateMarathon.fulfilled, (state) => {
        state.updateMarathonRequestLoading = false;
      })
      .addCase(updateMarathon.rejected, (state) => {
        state.updateMarathonRequestLoading = false;
      })
      .addCase(getParticipantInfo.pending, (state) => {
        state.isGetParticipantInfoLoading = true;
        state.participantNotFound = false;
      })
      .addCase(getParticipantInfo.fulfilled, (state, action) => {
        state.participantInfo = action.payload;
        state.isGetParticipantInfoLoading = false;
        state.participantNotFound = false;
      })
      .addCase(getParticipantInfo.rejected, (state, action) => {
        state.getParticipantInfoError = action.payload as string;
        state.isGetParticipantInfoLoading = false;
        state.participantNotFound = action.payload === "Not Found";
      })
      .addCase(getApplicationsByMarathonId.pending, (state) => {
        state.getParticipantsListLoading = true;
      })
      .addCase(getApplicationsByMarathonId.fulfilled, (state, action) => {
        state.participantsCount = action.payload.totalCount;
        state.participantsChangeDistanceCount =
          action.payload.changeDistanceCount;
        state.getParticipantsListLoading = false;
        state.participantsList = action.payload.userApplications;
      })
      .addCase(getApplicationsByMarathonId.rejected, (state) => {
        state.getParticipantsListLoading = false;
      })
      .addCase(getCorporateIssueByMarathonIdAndClientId.pending, (state) => {
        state.corporateIssueByMarathonIdAndClientIdLoading = true;
      })
      .addCase(
        getCorporateIssueByMarathonIdAndClientId.fulfilled,
        (state, action) => {
          state.corporateIssueByMarathonIdAndClientIdChangeDistanceCount =
            action.payload.data.changeDistanceCount;
          state.corporateIssueByMarathonIdAndClientIdTotalCount =
            action.payload.data.totalCount;
          state.corporateIssueByMarathonIdAndClientId = action.payload.data;
        }
      )
      .addCase(getCorporateIssueByMarathonIdAndClientId.rejected, (state) => {
        state.corporateIssueByMarathonIdAndClientIdLoading = false;
      })
      .addCase(
        getCorporateIssueByMarathonIdAndClientIdSearch.pending,
        (state) => {
          state.corporateIssueByMarathonIdAndClientIdLoading = true;
        }
      )
      .addCase(
        getCorporateIssueByMarathonIdAndClientIdSearch.fulfilled,
        (state, action) => {
          state.corporateIssueByMarathonIdAndClientId = action.payload;
        }
      )
      .addCase(
        getCorporateIssueByMarathonIdAndClientIdSearch.rejected,
        (state) => {
          state.corporateIssueByMarathonIdAndClientIdLoading = false;
        }
      )
      .addCase(getParticipantInfoBySearchName.pending, (state) => {
        state.getParticipantsListLoading = true;
      })
      .addCase(getParticipantInfoBySearchName.fulfilled, (state, action) => {
        state.participantsCount = action.payload.count;
        state.getParticipantsListLoading = false;
        state.participantsList = action.payload.userApplications;
      })
      .addCase(editParticipantProfileByAdmin.pending, (state) => {
        state.participantEditLoading = true;
      })
      .addCase(editParticipantProfileByAdmin.fulfilled, (state) => {
        state.participantEditLoading = false;
      })
      .addCase(editParticipantProfileByAdmin.rejected, (state) => {
        state.participantEditLoading = false;
      })
      .addCase(getUserInfoByStarterKitId.fulfilled, (state, action) => {
        if (action.payload) {
          state.userInfo = action.payload;
        }
      })
      .addCase(getUserInfoByStartNumber.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
      .addCase(getMarathonByIdForAdmin.fulfilled, (state, action) => {
        state.marathonForAdmin = action.payload;
      })
      .addCase(createVoucher.pending, (state) => {
        state.createVoucherLoading = true;
      })
      .addCase(createVoucher.fulfilled, (state) => {
        state.createVoucherLoading = false;
      })
      .addCase(createVoucher.rejected, (state) => {
        state.createVoucherLoading = false;
      })
      .addCase(addPromocodesToVouchers.pending, (state) => {
        state.addPromocodesToVouchersLoading = true;
        state.addPromocodesToVouchersError = null;
      })
      .addCase(addPromocodesToVouchers.fulfilled, (state) => {
        state.addPromocodesToVouchersLoading = false;
        state.addPromocodesToVouchersError = null;
      })
      .addCase(addPromocodesToVouchers.rejected, (state, action: any) => {
        state.addPromocodesToVouchersError = action.payload;
        state.addPromocodesToVouchersLoading = false;
      })
      .addCase(updateVoucher.pending, (state) => {
        state.editVoucherLoading = true;
      })
      .addCase(updateVoucher.fulfilled, (state) => {
        state.editVoucherLoading = false;
      })
      .addCase(updateVoucher.rejected, (state) => {
        state.editVoucherLoading = false;
      })
      .addCase(deleteNonActivatedPromocodes.pending, (state) => {
        state.deleteNonActivatedPromocodesLoading = true;
      })
      .addCase(deleteNonActivatedPromocodes.fulfilled, (state) => {
        state.deleteNonActivatedPromocodesLoading = false;
      })
      .addCase(deleteNonActivatedPromocodes.rejected, (state) => {
        state.deleteNonActivatedPromocodesLoading = false;
      })
      .addCase(getVoucherPromocodesByVoucherId.pending, (state) => {
        state.getVoucherPromocodesByVoucherIdLoading = true;
      })
      .addCase(getVoucherPromocodesByVoucherId.fulfilled, (state, action) => {
        state.marathonId = action.payload.marathonId;
        state.isVoucherActive = action.payload.isActive;
        state.voucherName = action.payload.voucherName;
        state.startKitIssueType = action.payload.startKitIssueType;
        state.getVoucherPromocodesByVoucherIdLoading = false;
        state.voucherPromocodesAmount = action.payload.promocodes.count;
        state.voucherPromocodes = action.payload.promocodes.query.map(
          (voucherPromocode: any) => {
            return { ...voucherPromocode, checked: false };
          }
        );
        state.discount = action.payload.discount;
      })
      .addCase(getCorporateClientsByMarathonIdThunk.pending, (state) => {
        state.corporateClientsByMarathonIdLoading = true;
      })
      .addCase(
        getCorporateClientsByMarathonIdThunk.fulfilled,
        (state, action) => {
          state.corporateClientsByMarathonIdTotalCount = action.payload.count;
          state.corporateClientsByMarathonId = action.payload.query;
        }
      )
      .addCase(getCorporateClientsByMarathonIdThunk.rejected, (state) => {
        state.corporateClientsByMarathonIdLoading = false;
      })
      .addCase(getCorporateOraganizationsInfoBySearch.pending, (state) => {
        state.corporateClientsByMarathonIdLoading = true;
      })
      .addCase(
        getCorporateOraganizationsInfoBySearch.fulfilled,
        (state, action) => {
          state.corporateClientsByMarathonId = action.payload.query;
        }
      )
      .addCase(getCorporateOraganizationsInfoBySearch.rejected, (state) => {
        state.corporateClientsByMarathonIdLoading = false;
      })
      .addCase(getVoucherPromocodesByVoucherId.rejected, (state) => {
        state.getVoucherPromocodesByVoucherIdLoading = false;
      })
      .addCase(getCorporateClientsThunk.pending, (state) => {
        state.isGetCorporateClientLoading = true;
      })
      .addCase(getCorporateClientsThunk.fulfilled, (state, action) => {
        state.corporateClient = action.payload.query;
        state.isGetCorporateClientLoading = false;
      })
      .addCase(getCorporateClientsThunk.rejected, (state, action) => {
        state.isGetCorporateClientLoading = false;
      })

      .addCase(getParticipantInfoBySearch.pending, (state) => {
        state.getVoucherPromocodesByVoucherIdLoading = true;
      })
      .addCase(getParticipantInfoBySearch.fulfilled, (state, action) => {
        state.marathonId = action.payload.marathonId;
        state.isVoucherActive = action.payload.isActive;
        state.voucherName = action.payload.voucherName;
        state.getVoucherPromocodesByVoucherIdLoading = false;
        state.voucherPromocodesAmount = action.payload.promocodes.count;
        state.voucherPromocodes = action.payload.promocodes.query.map(
          (voucherPromocode: any) => {
            return { ...voucherPromocode, checked: false };
          }
        );
      })
      .addCase(getParticipantInfoBySearch.rejected, (state) => {
        state.getVoucherPromocodesByVoucherIdLoading = false;
      })
      .addCase(deleteVoucherPromocodesById.pending, (state) => {
        state.deleteVoucherPromocodesByIdLoading = true;
      })
      .addCase(deleteVoucherPromocodesById.fulfilled, (state) => {
        state.deleteVoucherPromocodesByIdLoading = false;
      })
      .addCase(deleteVoucherPromocodesById.rejected, (state) => {
        state.deleteVoucherPromocodesByIdLoading = false;
      })
      .addCase(deleteVoucherById.pending, (state) => {
        state.deleteVoucherByIdLoading = true;
      })
      .addCase(deleteVoucherById.fulfilled, (state) => {
        state.deleteVoucherByIdLoading = false;
      })
      .addCase(deleteVoucherById.rejected, (state) => {
        state.deleteVoucherByIdLoading = false;
      })
      .addCase(createUser.pending, (state) => {
        state.createUserLoading = true;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.createUserLoading = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        if (
          action.payload === "Пользователь уже существует" ||
          action.payload === "Некорректный email адрес"
        ) {
          state.createUserEmailError = action.payload as string;
        }
        state.createUserLoading = false;
      })
      .addCase(getAdminsAndVolunteers.pending, (state) => {
        state.getAdminsAndVolunteersLoading = true;
      })
      .addCase(getAdminsAndVolunteers.fulfilled, (state, action) => {
        state.getAdminsAndVolunteersLoading = false;
        state.adminsAndVolunteers = action.payload.query;
      })
      .addCase(getAdminsAndVolunteers.rejected, (state, action) => {
        state.getAdminsAndVolunteersLoading = false;
        state.getAdminsAndVolunteersError = action.payload as string;
      })
      .addCase(deleteUserByOwner.pending, (state, action) => {
        state.deleteUserByOwnerLoading = [
          ...state.deleteUserByOwnerLoading,
          action.meta.arg,
        ];
      })
      .addCase(deleteUserByOwner.fulfilled, (state, action) => {
        state.deleteUserByOwnerLoading = state.deleteUserByOwnerLoading.filter(
          (id) => id !== action.meta.arg
        );
      })
      .addCase(deleteUserByOwner.rejected, (state, action: any) => {
        state.deleteUserByOwnerLoading = state.deleteUserByOwnerLoading.filter(
          (id) => id !== action.meta.arg
        );
      })
      .addCase(deleteMarathon.pending, (state) => {
        state.deleteMarathonLoading = true;
      })
      .addCase(deleteMarathon.fulfilled, (state) => {
        state.deleteMarathonLoading = false;
      })
      .addCase(deleteMarathon.rejected, (state) => {
        state.deleteMarathonLoading = false;
      })
      .addCase(getMarathonResultsByAdmin.pending, (state) => {
        state.isGetMarathonResultsLoading = true;
      })
      .addCase(getMarathonResultsByAdmin.fulfilled, (state, action: any) => {
        state.isGetMarathonResultsLoading = false;
        state.marathonResults = action.payload.results.query;
        state.marathonResultsCount = action.payload.results.count;
      })
      .addCase(getMarathonResultsByAdmin.rejected, (state, action) => {
        state.isGetMarathonResultsLoading = false;
      })
      .addCase(getMarathonResultsBySearch.pending, (state) => {
        state.isGetMarathonResultsBySearchLoading = true;
      })
      .addCase(getMarathonResultsBySearch.fulfilled, (state, action) => {
        state.marathonResults = action.payload.results.query;
        state.marathonResultsCount = action.payload.results.count;
        state.marathonResultsDistances = action.payload.distances;
        state.isGetMarathonResultsBySearchLoading = false;
      })
      .addCase(getMarathonResultsBySearch.rejected, (state) => {
        state.isGetMarathonResultsBySearchLoading = false;
      })
      .addCase(getUserPromocodes.pending, (state, action: any) => {
        state.userPromocodesLoading = true;
      })
      .addCase(getUserPromocodes.fulfilled, (state, action: any) => {
        state.userPromocodes = action.payload;
      })
      .addCase(getUserPromocodes.rejected, (state, action: any) => {
        state.userPromocodesLoading = false;
        state.userPromocodesError = action.error?.message;
      })
      .addCase(getUserInsurance.pending, (state, action: any) => {
        state.hasUserInsuranceLoading = true;
      })
      .addCase(getUserInsurance.fulfilled, (state, action: any) => {
        state.hasUserInsurance = action.payload;
      })
      .addCase(getUserInsurance.rejected, (state, action: any) => {
        state.hasUserInsuranceLoading = false;
        state.hasUserInsuranceError = action.error?.message;
      })
      .addCase(getMarathonDistances.pending, (state) => {
        state.marathonDistancesLoading = true;
      })
      .addCase(getMarathonDistances.fulfilled, (state, action) => {
        state.marathonDistances = action.payload;
        state.marathonDistancesLoading = false;
      })
      .addCase(getMarathonDistances.rejected, (state) => {
        state.marathonDistancesLoading = false;
      });
  },
});

export const {
  setGender,
  setIsShowFilter,
  setSpecificDistance,
  setAge,
  setCountry,
  setCountryIndex,
  setPayment,
  setParticipantType,
  setStartPackage,
  setParticipantStatus,
  setStartDateAcceptingApplications,
  setEndDateAcceptingApplications,
  setParticipantNumbersPrintDate,
  setUsersQuery,
  setUsersFilters,
  setPage,
  setPageSize,
  setClearUsersQuery,
  setDisabledPeopleQuery,
  clearUserInfo,
  clearExtendedMarathon,
  updateVoucherActivation,
  updateVoucherName,
  voucherPromocodeCheckedHandler,
  clearErrors,
  deleteUserLocally,
  clearServerError,
  setUnreadNotificationsCount,
  setAllUsersNotificationsCount,
  setDisabledUsersNotificationsCount,
} = marathonSlice.actions;

export default marathonSlice.reducer;
