import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { PERSONAL_CABINET_PATH, VOUCHERS_PROMOCODES_PATH } from "pages/paths";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { SubVoucherEditModal } from "shared/ui/adminPanel/subVoucherEditModal/SubVoucherEditModal";
import VoucherListTable from "../../../../admin/ui/Vouchers/VoucherListTable/VoucherListTable";
import { getUnssignedCountPerDistance } from "entities/viewer/model/thunks/getUnssignedCountPerDistance";
import { MoreIcon } from "assets/icons";
import closeIcon from "assets/images/modal/x.svg";
import styles from "./SubVoucher.module.scss";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Remove } from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useAppSelector } from "shared/lib/hooks/hooks";
import { getMarathonDistances } from "entities/admin/model/thunk/getMarathonDistances";
import { Label } from "entities/admin/lib/MUIStyles/MUIStyles";
import { addPromocodesToVouchers } from "entities/admin/model/thunk/addPromocodesToVouchers";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { addSlotsValidation } from "entities/admin/lib/formValidation/addSlotsValidation";
import { getSubVouchersCreateThunk } from "entities/viewer/model/thunks/getSubVouchersCreateThunk";

const SubVoucher = ({
  isAdmin,
  marathonName,
  voucherName,
  corporateClientName,
  voucherId,
}) => {
  const { corporateClientId, subVoucherId } = useParams();
  const { t } = useTranslation();
  const [activeStatus, setActiveStatus] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAddNewSlotesModalOpen, setAddNewSlotesModalOpen] = useState(false);
  const unssignedCountPerDistance = useSelector(
    (state) => state?.personalCabinet?.unssignedCountPerDistance
  );
  const userRole = useSelector((state) => state.users.userRole);
  const subVouchers = useSelector(
    (state) => state?.personalCabinet?.subVouchers
  );
  const subVouchersLoading = useSelector(
    (state) => state?.personalCabinet?.subVouchersLoading
  );
  const marathonDistances = useAppSelector(
    (state) => state.marathon.marathonDistances
  );
  const location = useLocation();
  const marathonId = location.state?.marathonId;

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addSlotsValidation),
    defaultValues: { fields: [{ value: "", slots: "" }] },
  });

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const navigate = useNavigate();

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const showTable = false;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    if (userRole === "Owner") {
      dispatch(getUnssignedCountPerDistance({ voucherId }));
    } else {
      dispatch(getUnssignedCountPerDistance({ voucherId: subVoucherId }));
    }
  }, [userRole, voucherId, subVoucherId]);

  const columns = useMemo(() => [
    {
      accessorKey: "id",
      header: "№",
      size: 70,
      Cell: ({ row }) => {
        return <>{row.index + 1 + (pagination.page - 1) * pagination.pageSize}</>;
      },
      enableSorting: false,
    },
    {
      accessorKey: "startKitIssueType",
      header: t("corporateClient.startKitIssueType"),
      size: 150,
      Cell: (value) => {
        switch (value.renderedCellValue) {
          case "Individual":
            return t("corporateClient.individual");
          case "Сorporate":
            return t("corporateClient.corporate");
          default:
            break;
        }
        return <Box>{value.renderedCellValue}</Box>;
      },
    },
    {
      accessorKey: "voucherName",
      header: t("corporateClient.subvoucher"),
      size: 200,
      Cell: (value) => {
        return <Box>{value.renderedCellValue}</Box>;
      },
    },
    {
      accessorKey: "totalPromocodes",
      header: t("corporateClient.slots"),
      size: 70,
      Cell: (value) => {
        const subvoucher = value.row.original;
        return (
          <Box>
            {subvoucher.usedPromocodes}/{subvoucher.totalPromocodes}
          </Box>
        );
      },
    },
    {
      accessorKey: "expirationDate",
      header: t("corporateClient.active_until"),
      size: 100,
      Cell: (value) => dayjs(value.renderedCellValue).format("DD.MM.YYYY"),
    },
    {
      accessorKey: "isActive",
      header: t("corporateClient.status"),
      size: 100,
      Cell: (value) => (
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            color: value.renderedCellValue ? "#237657" : "#FA0E00",
            textTransform: "capitalize",
            borderRadius: "4px",
            background: value.renderedCellValue ? "#E8FFE0" : "#FFECEB",
            fontFamily: "Raleway",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {value.renderedCellValue
            ? t("corporateClient.active")
            : t("corporateClient.un_active")}
        </Button>
      ),
    },
    {
      accessorKey: "action",
      header: "",
      size: 100,
      Cell: (value) => {
        const clickedSubVoucherId = value.row.original.id;
        const voucherName = value.row.original.voucherName;
        const isActive = value.row.original.isActive;
        setActiveStatus(isActive);
        return (
          <div onClick={handleClick}>
            <SubVoucherEditModal
              pageSubVoucherId={subVoucherId}
              corporateClientId={corporateClientId}
              clickedSubVoucherId={clickedSubVoucherId}
              name={voucherName}
              isActive={isActive}
            />
          </div>
        );
      },
    },
  ]);

  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (corporateClientId) {
      dispatch(
        getSubVouchersCreateThunk({
          voucherId: subVoucherId,
          corporateClientId,
          pagination,
        })
      )
    } else if (voucherId) {
      dispatch(
        getSubVouchersCreateThunk({
          voucherId: voucherId,
          corporateClientId,
          pagination,
        })
      );
    }
  }, [corporateClientId, dispatch, pagination, subVoucherId, voucherId, t, activeStatus]);

  const getPromocodeColor = (count) => {
    if (count <= 3) return "#FA0E00";
    if (count <= 15) return "#BA8E1E";
    if (count >= 20) return "#237657";

    return "#CBD5E1";
  };

  const handleOnPageChange = (event, page) =>
    setPagination((prevPagination) => ({
      ...prevPagination,
      page,
    }));

  const table = useMaterialReactTable({
    columns,
    data: subVouchers?.query || [],
    enableTopToolbar: false,
    enableColumnActions: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    initialState: { density: "comfortable" },
    enablePagination: false,
    state: { pagination, isLoading: subVouchersLoading },
    muiTableHeadRowProps: () => ({
      style: {
        background: "#ECF2F7",
        borderRadius: "20px",
        border: "none",
        boxShadow: "none",
      },
    }),
    muiTablePaperProps: {
      style: {
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
        boxShadow: "none ",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        height: "10px",
        fontSize: "12px",
        lineHeight: "20px",
        fontWeight: 600,
        fontFamily: "Raleway",
        border: "none",
        "& .MuiTableSortLabel-icon": {
          color: "black !important",
          opacity: 1,
        },
      },
    },
    muiBottomToolbarProps: {
      style: {
        border: "none",
        boxShadow: "none ",
      },
    },
    muiTableBodyCellProps: {
      style: {
        fontSize: "12px",
        fontVariantNumeric: "lining-nums proportional-nums",
        textOverflow: "ellipsis",
        fontFeatureSettings: '"liga" off',
        lineHeight: "20px",
        fontWeight: 500,
        fontFamily: "Raleway",
        color: "#3E444A",
        border: "none",
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const subVoucher = row.original;
        if (!subVoucher.id) {
          return;
        }
        !isAdmin
          ? navigate(
            `/${PERSONAL_CABINET_PATH}/${VOUCHERS_PROMOCODES_PATH}/${subVoucher.id}`
          )
          : navigate(`/admin_panel/vouchers/promocodes/${subVoucher.id}`);
      },
      sx: {
        //stripe the rows, make odd rows a darker color
        cursor: "pointer",
        "& tr:nth-of-type(even) > td": {
          backgroundColor: "#F5FAFF",
        },
      },
    }),
    muiPaginationProps: {
      count: subVouchers?.totalCount,
      page: pagination.page,
      onPageChange: handleOnPageChange,
      rowsPerPage: pagination.pageSize,
    },
  });

  useEffect(() => {
    if (marathonId) {
      dispatch(getMarathonDistances(marathonId));
    }
  }, [marathonId]);

  const handleAddNewSlotesModalClose = () => {
    setAddNewSlotesModalOpen(false);
    reset();
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const watchFields = watch("fields");

  const onSubmit = async (data) => {
    const promocodeData = data.fields.map((field) => ({
      distanceId: field.value,
      quantity: field.slots,
    }));

    const requestBody = {
      voucherId,
      promocodes: promocodeData,
    };
    try {
      await dispatch(addPromocodesToVouchers(requestBody));

      setAddNewSlotesModalOpen(false);
      reset();

      if (userRole === "Owner") {
        dispatch(getUnssignedCountPerDistance({ voucherId }));
      } else {
        dispatch(getUnssignedCountPerDistance({ voucherId: subVoucherId }));
      }

      if (subVoucherId) {
        dispatch(
          getSubVouchersCreateThunk({
            voucherId: subVoucherId,
            corporateClientId,
            pagination,
          })
        );
      }
    } catch (error) {
      console.error(
        "Ошибка при добавлении промокодов или получении данных:",
        error
      );
    }
  };

  return (
    <div
      style={{
        width: "100%",
        flex: "1",
        maxWidth: "1280px",
        margin: "15px auto 0",
      }}
    >
      <span
        style={{ color: "#0090D1", whiteSpace: "pre", cursor: "pointer" }}
        onClick={() => nav(-1)}
      >
        {t("corporateClient.vouchers")} &gt;{" "}
      </span>
      <span style={{ whiteSpace: "pre" }}> {corporateClientName}</span>
      <div style={{ margin: "20px 0" }}>
        {" "}
        {marathonName} {marathonName ? "|" : ""} {voucherName}
      </div>
      {(userRole === "Owner" || userRole === "Admin") && (
        <div className={styles.voucherNameContainer}>
          <h2 className={styles.voucherName}>{voucherName}</h2>
          <Button type="button" onClick={handleMenuClick}>
            <MoreIcon />
          </Button>
        </div>
      )}
      <Box sx={{ display: "flex", gap: "16px" }}>
        <Box className="container-personalCabinet" sx={{ maxWidth: "350px" }}>
          <h2
            className="title-personalCabinet"
            style={{ marginBottom: "28px" }}
          >
            {t("corporateClient.distances")}
          </h2>
          <Box
            sx={{
              maxWidth: 400,
              backgroundColor: "#F1F5F9",
              padding: "8px",
              borderRadius: 2,
              maxHeight: "50vh",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#CBD5E1",
                borderRadius: "6px",
              },
              "&::-webkit-scrollbar": { display: "none" },
              "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
            }}
          >
            {unssignedCountPerDistance &&
              unssignedCountPerDistance.length > 0 ? (
              unssignedCountPerDistance.map((promocode) => (
                <Box
                  key={promocode?.key}
                  sx={{
                    mt: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    p: "12px 16px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: 2,
                    width: "100%",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography
                    sx={{
                      flexGrow: 1,
                      minWidth: 0,
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t("vouchersPage.freeSlotsAtDistance")}{" "}
                    {promocode?.distanceName}
                  </Typography>

                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderColor: "#CBD5E1",
                      borderStyle: "dashed",
                      alignSelf: "stretch",
                    }}
                  />

                  <Typography
                    sx={{
                      minWidth: 40,
                      textAlign: "center",
                      color: getPromocodeColor(promocode?.promocodeCount),
                    }}
                  >
                    {promocode?.promocodeCount}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography sx={{ textAlign: "center", color: "#CBD5E1" }}>
                {t("vouchersPage.noAvailableDistances")}
              </Typography>
            )}
          </Box>
        </Box>
        <div className={"container-personalCabinet"}>
          <h2
            className="title-personalCabinet"
            style={{ marginBottom: "28px" }}
          >
            {t("corporateClient.subvouchers")}
          </h2>
          <Box style={{ overflowX: "auto", flex: 1 }}>
            <Box></Box>
            <MaterialReactTable table={table || []} />
          </Box>
        </div>
      </Box>
      {subVouchers?.query.length === 0 ? null : (
        <Stack
          spacing={2}
          sx={{
            margin: "30px auto 20px auto",
            alignItems: "center",
          }}
        >
          <Pagination
            count={Math.ceil(subVouchers?.count / pagination.pageSize) || 5}
            page={pagination.page}
            onChange={handleOnPageChange}
            color="primary"
            size="medium"
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      )}
      {showTable && (
        <VoucherListTable
          subVoucherId={subVoucherId}
          corporateClientId={corporateClientId}
          pagination={pagination}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        disableScrollLock={true}
        sx={{
          padding: "0px",
          "& .MuiPaper-root": {
            borderRadius: "16px",
            padding: "0px",
          },
          ".MuiModal-root": {
            padding: "0px",
            margin: "0px",
          },
        }}
        style={{ borderRadius: "10px" }}
        onClose={handleClose}
      >
        <MenuItem
          className={styles.voucherMenuItem}
          sx={{
            display: "flex",
            padding: "10px",
            fontFamily: "Raleway",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#3E444A",
            borderRadius: "8px",
            "&:hover": { background: "#F0F8FF", color: "#0090D1" },
          }}
          onClick={() => {
            handleClose();
            setAddNewSlotesModalOpen(true);
          }}
        >
          <ListItemIcon sx={{ minWidth: "24px" }}>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <span>Добавить слоты</span>
        </MenuItem>
      </Menu>

      <Dialog
        open={isAddNewSlotesModalOpen}
        onClose={handleAddNewSlotesModalClose}
        disableScrollLock={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
        maxWidth="md"
        sx={{
          ".MuiDialog-paper": {
            borderRadius: "16px",
            padding: "24px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle sx={{ px: 0 }}>Добавить слоты</DialogTitle>
          <img
            onClick={handleAddNewSlotesModalClose}
            src={closeIcon}
            className={styles.modalCloseIcon}
            alt="Закрыть"
          />
        </Box>
        <DialogContent sx={{ px: 0 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => {
              const selectedDistance = watchFields?.[index].value;
              const selectedMarathon = marathonDistances?.find(
                (marathon) => marathon?.id === selectedDistance
              );
              const selectedDistances =
                watchFields?.map((item) => item.value) || [];

              const handleDistanceChange = (event) => {
                const newDistance = event.target.value;
                setValue(`fields.${index}.value`, newDistance);
                setValue(`fields.${index}.slots`, "");
              };

              return (
                <Box>
                  <Stack
                    key={field.id}
                    direction="row"
                    spacing={2}
                    display="flex"
                    alignItems="center"
                    mb="15px"
                  >
                    <Box sx={{ width: "70%", position: "relative" }}>
                      <InputLabel htmlFor={`distance${index}`} sx={Label}>
                        Дистанция
                      </InputLabel>
                      <Controller
                        name={`fields.${index}.value`}
                        control={control}
                        rules={{ required: "Выберите дистанцию" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            fullWidth
                            displayEmpty
                            defaultValue=""
                            value={field.value || ""}
                            onChange={(e) => {
                              field.onChange(e);
                              handleDistanceChange(e);
                              trigger(`fields.${index}.value`);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "13px",
                                border: errors.fields?.[index]?.value
                                  ? "2px solid #b63d3c"
                                  : "1px solid #C0D4E3",
                              },
                              backgroundColor: "white",
                              "& .MuiInputBase-input": {
                                padding: "10px",
                              },
                              cursor: "pointer",
                            }}
                          >
                            <MenuItem value="">
                              <Typography
                                disabled
                                className={styles.selectPlaceholder}
                              >
                                Выберите дистанцию
                              </Typography>
                            </MenuItem>
                            {marathonDistances?.map((marathonDistance) => (
                              <MenuItem
                                key={marathonDistance?.id}
                                value={marathonDistance?.id}
                                disabled={selectedDistances?.includes(
                                  marathonDistance?.id
                                )}
                              >
                                {marathonDistance?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.fields?.[index]?.value && (
                        <Typography
                          color="error"
                          variant="caption"
                          sx={{
                            position: "absolute",
                            bottom: "-20px",
                            left: 0,
                          }}
                        >
                          {errors.fields?.[index]?.value?.message || " "}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ width: "30%", position: "relative" }}>
                      <InputLabel sx={Label}>Слоты</InputLabel>

                      <Controller
                        name={`fields.${index}.slots`}
                        control={control}
                        rules={{ required: "Укажите количество слотов" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            type="number"
                            placeholder="Введите кол-во слотов"
                            disabled={
                              !selectedMarathon ||
                              selectedMarathon?.remainingPlaces === 0
                            }
                            inputProps={{
                              min: 1,
                              max: selectedMarathon?.remainingPlaces || 0,
                            }}
                            onChange={(e) => {
                              const value = Math.min(
                                Number(e.target.value),
                                selectedMarathon?.remainingPlaces || 0
                              );
                              setValue(
                                `fields.${index}.slots`,
                                value.toString()
                              );
                              trigger(`fields.${index}.slots`);
                            }}
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "13px",
                                border: errors.fields?.[index]?.slots
                                  ? "2px solid #b63d3c"
                                  : "1px solid #C0D4E3",
                              },
                              backgroundColor: "white",
                              "& .MuiInputBase-input": {
                                padding: "10px",
                                textAlign: "center",
                              },
                              cursor: "pointer",
                            }}
                          />
                        )}
                      />
                      {errors.fields?.[index]?.slots && (
                        <Typography
                          color="error"
                          variant="caption"
                          sx={{
                            position: "absolute",
                            bottom: "-20px",
                            left: 0,
                          }}
                        >
                          {errors.fields?.[index]?.slots?.message || " "}
                        </Typography>
                      )}
                    </Box>

                    <IconButton
                      onClick={() => remove(index)}
                      disabled={fields.length === 1}
                    >
                      <Remove sx={{ marginTop: "17px" }} />
                    </IconButton>
                  </Stack>
                  <Box
                    sx={{
                      paddingBottom: "15px",
                      textAlign: "end",
                      paddingRight: "5%",
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      mt: "-5px",
                    }}
                  >
                    {selectedMarathon && (
                      <Typography variant="caption" color="textSecondary">
                        Максимальное количество мест:{" "}
                        {selectedMarathon?.remainingPlaces}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
            <IconButton
              onClick={() => append({ value: "", slots: "" })}
              className={styles.addButton}
            >
              <AddOutlinedIcon />
              <Typography>Добавить другую дистанцию</Typography>
            </IconButton>
            <DialogActions sx={{ px: 0 }}>
              <Button
                type="button"
                variant="text"
                onClick={handleAddNewSlotesModalClose}
                sx={{
                  textTransform: "none",
                  color: "#0090D1",
                }}
              >
                Отмена
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#0090D1",
                  borderRadius: "12px",
                  padding: "8px 24px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#0090D1",
                  },
                }}
              >
                Сохранить
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SubVoucher;
