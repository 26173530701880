import { alertError } from "shared/api/alertError";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { personalCabinetAPI } from "../api/index";

interface postApplicationInsuranceCreateThunk {
  distanceId: number | undefined;
}

export const postApplicationInsuranceCreateThunk = createAsyncThunk(
  "personalCabinet/postApplicationInsurance",
  async (data: postApplicationInsuranceCreateThunk, { rejectWithValue }) => {
    try {
      const newTab = window.open("about:blank", "_blank");

      const res = await personalCabinetAPI.postApplicationInsurance(data);
      if (res.data) {
        if (newTab) {
          newTab.location.href = res.data;
        } else {
          window.location.href = res.data;
        }
      } else {
        window.location.reload();
      }
      return res.data;
    } catch (error: any) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return error.response.data.code;
    }
  }
);
