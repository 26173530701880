import {
  IPagination,
  IParticipantData,
  IUserInfo,
  IVoucherData,
  IVoucherFilter,
} from "../../lib/types";

import axiosApi from "shared/api/axiosApi";
import { checkCurrentLanguage } from "shared/lib/checkCurrentLanguage";
import { IGetCorporateIssueByMarathonIdAndClientIdRes } from "../types/getCorporateIssueByMarathonIdAndClientId";
import { IGetUserPromocodesBody } from "../thunk/getUserPromocodes";

export const typedAdminAPI = {
  getMarathons: async (corporateClientId?: number) => {
    return await axiosApi.get(
      "/marathons?page=1&pageSize=500&orderBy=Id%20desc",
      {
        headers: {
          "Accept-Language": "ru-ru",
        },
        params: {
          corporateClientId,
        },
      }
    );
  },
  getNotCompletedMarathons: async (corporateClientId?: number) => {
    return await axiosApi.get(
      `vouchers/promocodes/users/${corporateClientId}`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getMarathonById: async (id: number) => {
    return await axiosApi.get(`marathons/${id}`, {
      headers: {
        "Accept-Language": "ru-ru",
      },
    });
  },
  getMarathonByIdForAdmin: async (marathonId: number) => {
    return await axiosApi.get(`marathons/admin/${marathonId}`);
  },
  createVoucher: async (voucherData: IVoucherData) => {
    return await axiosApi.post("vouchers", voucherData, {
      headers: {
        "Accept-Language": "ru-ru",
      },
    });
  },
  searchVouchers: async (searchTerm: string, marathonId: number) => {
    return await axiosApi.get(`vouchers/startkit/${marathonId}`, {
      params: { searchTerm },
      headers: {
        "Accept-Language": "ru-ru",
      },
    });
  },
  getVouchers: async (
    pagination?: IPagination,
    params?: IVoucherFilter,
    sorting?: any[]
  ) => {
    return await axiosApi.get(
      `vouchers${pagination
        ? "?page=" + pagination?.page + "&pageSize=" + pagination?.pageSize
        : ""
      } ${sorting && sorting[0]
        ? sorting[0].desc
          ? `&orderBy=${sorting[0].id}%20desc`
          : `&orderBy=${sorting[0].id}%20asc`
        : ""
      } `,
      {
        params,
        paramsSerializer: (params) => {
          return (
            `filter=` +
            Object.keys(params)
              .map((key) =>
                params[key] !== null ? key + "%3D" + params[key] : undefined
              )
              .filter((item) => item)
              .join("&")
              .replace(/&/g, "%2C")
          );
        },
        headers: {
          "Accept-Language": "ru-ru",
        },
      }
    );
  },
  // getCorporateClients: async () => {
  //   return await axiosApi.get("corporateclients");
  // },
  getCorporateClients: async () => {
    return await axiosApi.get(
      `/corporateclients?page=1&pageSize=500&orderBy=Id%20desc`,
      {
        headers: {
          "Accept-Language": "ru-ru",
        },
      }
    );
  },
  getCorporateClientsByMarathonId: async (id: number) => {
    return await axiosApi.get(`/corporateclients/${id}`, {
      headers: {
        "Accept-Language": "ru-ru",
      },
    });
  },
  getCorporateOrganizationsInfoBySearch: async (participantInfo: {
    participantSearch: string;
    id: number;
  }) => {
    const baseUrl = `/corporateclients/${participantInfo.id}`;

    const filters = [
      `name!=null,name=*${participantInfo.participantSearch}/i`
    ];

    const filterString = filters.join(')|(');
    const queryString = `?filter=(${filterString})`;

    return await axiosApi.get(`${baseUrl}${queryString}`);
  },
  updateCorporateClientResponsible: async (resposibleData: {
    voucherId: number;
    usersId: number[];
  }) => {
    return await axiosApi.put(
      `/vouchers/${resposibleData.voucherId}/responsibleUser`,
      {
        usersId: resposibleData.usersId,
        voucherId: resposibleData.voucherId
      },
      {
        headers: {
          "Accept-Language": "ru-ru",
        },
      }
    );
  },
  getApplicationsByMarathonId: async (applicationData: any) => {
    const distanceAgeId = applicationData.age.split(" ");
    const distanceParts = applicationData.specificDistance.split(" ");
    const distanceId = distanceParts[1];
    return await axiosApi.get(
      `/applications/marathon/${+applicationData.id}/individualIssuance?${"page=" + applicationData.page + "&pageSize=" + applicationData.pageSize}`,
      {
        params: {
          "gender": applicationData.gender || undefined,
          "country": applicationData.countryIndex || undefined,
          "status": applicationData.participantStatus || undefined,
          starterKit: applicationData.startPackage || undefined,
          paymentMethod: applicationData.payment || undefined,
          distanceId: distanceId || undefined,
          distanceAgeId: distanceAgeId[distanceAgeId.length - 1] || undefined,
          "isDisable": applicationData.participantType || undefined,
        },
        paramsSerializer: (params) => {
          const filter = Object.keys(params)
            .map((key) => (params[key] ? `${key}%3D${params[key]}` : undefined))
            .filter(Boolean)
            .join("%2C");
          return filter ? `filter=${filter}` : "";
        },
      }
    );
  },
  getParticipantInfoBySearchName: async (participantInfo: {
    participantSearch: string;
    id: number;
  }) => {
    const baseUrl = `/applications/marathon/${participantInfo.id}/individualIssuance`;
    const filters = [
      `surname!=null,surname=*${participantInfo.participantSearch}/i`,
      `name!=null,name=*${participantInfo.participantSearch}/i`,
      `nativeSurname!=null,nativeSurname=*${participantInfo.participantSearch}/i`,
      `nativeName!=null,nativeName=*${participantInfo.participantSearch}/i`,
      `email!=null,email=*${participantInfo.participantSearch}/i`,
      `startNumber!=null,startNumber=*${participantInfo.participantSearch}/i`,
      `voucherName!=null,voucherName=*${participantInfo.participantSearch}/i`
    ];

    const filterString = filters.join(')|(');
    const queryString = `?filter=(${filterString})`;

    return await axiosApi.get(`${baseUrl}${queryString}`);
  },
  getCorporateIssueByMarathonIdAndClientId: async (
    corporateUserList: any
  ): Promise<IGetCorporateIssueByMarathonIdAndClientIdRes> => {
    const distanceAgeId = corporateUserList.age.split(" ");
    const distanceParts = corporateUserList.specificDistance.split(" ");
    const distanceId = distanceParts[1];
    return await axiosApi.get(
      `/applications/marathon/${corporateUserList.maratonId}/corporateIssuance/${corporateUserList.corporateClientId}?${"page=" + corporateUserList.page + "&pageSize=" + corporateUserList.pageSize}`,
      {

        params: {
          "gender": corporateUserList.gender || undefined,
          "country": corporateUserList.countryIndex || undefined,
          "status": corporateUserList.participantStatus || undefined,
          starterKit: corporateUserList.startPackage || undefined,
          paymentMethod: corporateUserList.payment || undefined,
          distanceId: distanceId || undefined,
          distanceAgeId: distanceAgeId[distanceAgeId.length - 1] || undefined,
          "isDisable": corporateUserList.participantType || undefined,
        },
        paramsSerializer: (params) => {
          const filter = Object.keys(params)
            .map((key) => (params[key] ? `${key}%3D${params[key]}` : undefined))
            .filter(Boolean)
            .join("%2C");
          return filter ? `filter=${filter}` : "";
        },
        headers: {
          "Accept-Language": "ru-ru",
        },
      },
    )
  },
  getCorporateIssueByMarathonIdAndClientIdSearch: async (participantInfo: {
    marathonId: string,
    corporateClientId: string
    participantSearch: string;
  }) => {
    const baseUrl = `/applications/marathon/${participantInfo.marathonId}/corporateIssuance/${participantInfo.corporateClientId}`;

    const filters = [
      `surname!=null,surname=*${participantInfo.participantSearch}/i`,
      `name!=null,name=*${participantInfo.participantSearch}/i`,
      `nativeSurname!=null,nativeName=*${participantInfo.participantSearch}/i`,
      `email!=null,email=*${participantInfo.participantSearch}/i`,
      `startNumber!=null,startNumber=*${participantInfo.participantSearch}/i`,
      `voucherName!=null,voucherName=*${participantInfo.participantSearch}/i`
    ];

    const filterString = filters.join(')|(');
    const queryString = `?filter=(${filterString})`;

    return await axiosApi.get(`${baseUrl}${queryString}`);
  },
  editParticipantProfileByAdmin: async (participantData: IParticipantData) => {
    return await axiosApi.put(`/users/${participantData.id}`, {
      name: participantData.name,
      surname: participantData.surname,
      personalNumber: participantData.personalNumber,
      nativeName: participantData.nativeName,
      nativeSurname: participantData.nativeSurname,
      phoneNumber: participantData.phoneNumber,
      extraPhoneNumber: participantData.extraPhoneNumber,
      email: participantData.email,
      dateOfBirth: participantData.dateOfBirth,
      tshirt: participantData.tShirt,
      country: participantData.country,
      gender: participantData.gender,
      isDeleted: participantData.isDeleted,
      isDisable: participantData.isDisable,
      dateOfConfirmation: participantData.dateOfConfirmation,
    });
  },
  addPromocodesToVoucher: async (
    voucherId: number | undefined,
    voucherData: any
  ) => {
    return await axiosApi.post(
      `vouchers/${voucherId}/promocodes`,
      { promocodes: voucherData },
      {
        headers: {
          "Accept-Language": "ru-ru",
        },
      }
    );
  },
  updateVoucher: async (voucherData: {
    isActive: boolean;
    name: string;
    id: number;
  }) => {
    return await axiosApi.put("vouchers", voucherData, {
      headers: {
        "Accept-Language": "ru-ru",
      },
    });
  },
  deleteNonActivatedPromocodes: async (voucherId: number) => {
    return await axiosApi.delete(
      `/vouchers/${voucherId}/non-activated-promocodes`
    );
  },
  deleteMarathonNonActivatedPromocodes: async (marathonId: number) => {
    return await axiosApi.delete(
      `/marathons/${marathonId}/non-activated-promocodes`
    );
  },
  deleteSubVoucher: async (subVoucherId: number) => {
    return await axiosApi.delete(
      `/vouchers/subvouchers/${subVoucherId}`
    );
  },
  deactivateSubVoucher: async (subVoucherData: {
    subVoucherId: number,
    name: string,
    isActive: boolean,
    startKitIssueType?: number,
  }) => {
    return await axiosApi.put(`/vouchers/subvouchers`, subVoucherData);
  },
  updateSubVoucher: async (subVoucherData: {
    subVoucherId: number,
    name: string,
    isActive: boolean,
    startKitIssueType: number,
  }) => {
    return await axiosApi.put(`/vouchers/subvouchers`, subVoucherData);
  },

  deleteVoucherPromocodesById: async (
    voucherId: number,
    promocodesId: number[]
  ) => {
    return await axiosApi.delete(`/vouchers/${voucherId}/promocodes`, {
      headers: {
        "Accept-Language": "ru-ru",
      },
      data: promocodesId,
    });
  },
  deleteVoucherById: async (voucherId: number) => {
    return await axiosApi.delete(`/vouchers/${voucherId}`);
  },
  getVoucherById: async (voucherId: number) => {
    return await axiosApi.get(`/vouchers/${voucherId}`);
  },
  getStarterkitByVoucherId: async (voucherData: {
    pageNumber: number;
    pageSize: number;
    voucherId: number;
  }) => {
    return await axiosApi.get(
      `/vouchers/corp-startkit/${voucherData.voucherId}`,
      {
        params: {
          pageNumber: (voucherData.pageNumber as number) + 1,
          pageSize: voucherData.pageSize,
        },
      }
    );
  },
  getUserPromocodes: async (data: IGetUserPromocodesBody) => {
    const filter = `marathonId=${data?.marathonId}`
    return await axiosApi.get(
      `vouchers/promocodes/users/${data?.userId}?filter=${filter}`
    )
  },
  getVoucherPromocodesByVoucherId: async (voucherData: {
    page: number;
    pageSize: number;
    voucherId: number;
    selectedPromocodeStatus?: string;
    selectedDistanceName?: string;
  }) => {
    return await axiosApi.get(
      `/vouchers/${voucherData.voucherId}/promocodes?page=${voucherData.page}&pageSize=${voucherData.pageSize}&orderBy=IsActivated%20desc`,
      {
        params: {
          distance:
            voucherData.selectedDistanceName === ""
              ? undefined
              : voucherData.selectedDistanceName,
          isActivated:
            voucherData.selectedPromocodeStatus === ""
              ? undefined
              : voucherData.selectedPromocodeStatus,
        },
        paramsSerializer: (params) => {
          return (
            "filter=" +
            Object.keys(params)
              .map((key) =>
                params[key] ? key + "%3D" + params[key] : undefined
              )
              .filter((item) => item)
              .join("&")
              .replace(/&/g, "%2C")
          );
        },
      }
    );
  },
  createUser: (userData: any) => {
    return axiosApi.post("/auth/registeradmin", userData, {
      headers: {
        "Accept-Language": "ru-ru",
      },
    });
  },
  getParticipantInfoBySearch: (participantInfo: {
    voucherId: number;
    search: string;
  }) => {
    return axiosApi.get(
      `/vouchers/${participantInfo.voucherId}/promocodes${participantInfo.search &&
      `?filter=user%21%3Dnull%2C%28%28user.surname%21%3Dnull%2Cuser.name%21%3Dnull%2Cuser.fullName%3D%2A${participantInfo.search}%2Fi%29%7C%28user.surname%21%3Dnull%2Cuser.name%21%3Dnull%2Cuser.fullNameR%3D%2A${participantInfo.search}%2Fi%29%7C%28user.phoneNumber%21%3Dnull%2Cuser.phoneNumber%3D%2A${participantInfo.search}%2Fi%29%29%7C%28code%21%3Dnull%2Ccode%3D%2A${participantInfo.search}%2Fi%29`
      }`
    );
  },
  getUserInfoBySearchTerm: async (
    marathonId: number,
    searchTerm: string | number
  ) => {
    return await axiosApi.get<IUserInfo>(
      `applications/starterkit/${marathonId}`,
      { params: { searchTerm } }
    );
  },
  putStarterKitStatus: async (
    applicationId: number | undefined,
    starterKit: number,
    fullNameRecipient: string | null
  ) => {
    return await axiosApi.put(
      `applications/starterkit/${applicationId}`,
      {
        starterKit: starterKit,
        fullNameRecipient: fullNameRecipient,
      },
      {
        headers: {
          "content-type": "multipart/form-data",
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getUserInfoByStartNumber: async (marathonData: {
    marathonId: number;
    startNumber: string | number;
  }) => {
    return await axiosApi.get(
      `/applications/starterkit-number/${marathonData.marathonId}/${marathonData.startNumber}`,
      {
        headers: {
          "Accept-Language": "ru-ru",
        },
      }
    );
  },
  getAdminsAndVolunteers: async () => {
    return await axiosApi.get("/users/admins");
  },
  deleteUserByOwner: async (userId: string) => {
    return await axiosApi.delete(`/users/${userId}`);
  },
  deleteMarathon: async (marathonId: number) => {
    return await axiosApi.delete(`/marathons/${marathonId}`, {
      headers: {
        "Accept-Language": "ru-ru",
      },
    });
  },
  getMarathonResultsByAdmin: async (marathonResults: {
    marathonId: number;
    page: number;
    pageSize: number;
    gender: string;
    distance: string;
    distanceAgeId: string;
  }) => {
    return await axiosApi.get(
      `/results/marathon/${marathonResults.marathonId}?page=${marathonResults.page}&pageSize=${marathonResults.pageSize}`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
        params: {
          "application.distance":
            marathonResults.distance === ""
              ? undefined
              : marathonResults.distance.replace(/([(),|]|\/i)/g, "\\$1"),
          "user.gender":
            marathonResults.gender === "" ? undefined : marathonResults.gender,
          "application.distanceAgeId":
            marathonResults.distanceAgeId === ""
              ? undefined
              : marathonResults.distanceAgeId,
        },
        paramsSerializer: (params) => {
          return (
            "filter=" +
            Object.keys(params)
              .map((key) =>
                params[key] ? key + "%3D" + params[key] : undefined
              )
              .filter((item) => item)
              .join("&")
              .replace(/&/g, "%2C")
          );
        },
      }
    );
  },
  getMarathonResultsBySearch: async (marathonData: {
    marathonId: number;
    participantSearch: string;
  }) => {
    return await axiosApi.get(
      `/results/marathon/${marathonData.marathonId}${marathonData.participantSearch &&
      `?filter=%28%28user%21%3Dnull%29%2C%28user.fullName%3D%2A${marathonData.participantSearch}%2Fi%29%7C%28user.fullNameR%3D%2A${marathonData.participantSearch}%2Fi%29%29%7C%28application%21%3Dnull%2Capplication.number%3D${marathonData.participantSearch}%29`
      }`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
  getMarathonDistances: async (marathonId: any) => {
    return await axiosApi.get(
      `/marathons/${marathonId}/distances`,
      {
        headers: {
          "Accept-Language": checkCurrentLanguage(),
        },
      }
    );
  },
};
