import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api";
import { toast } from "react-toastify";
import i18next from "i18next";

export const asignPromocode = createAsyncThunk(
  "user/asignPromocode",
  async (query: any, { rejectWithValue, signal }) => {
    try {
      const { data } = await adminAPI.postAsignPromocode(query, signal);
      toast.success(String(i18next.t('promoCodeSuccessfullyAssigned')));
      return data;
    } catch (error: any) {
      if (error?.response?.data?.status === 400) {
        toast.error(error?.response?.data?.title || String(i18next.t('errorAssigningPromoCode')))
      }
      return rejectWithValue(error);
    }
  }
);