import { createAsyncThunk } from "@reduxjs/toolkit";
import { alertError } from "shared/api/alertError";
import { personalCabinetAPI } from "../api/index";

interface postApplicationCreateThunk {
  distanceId: number | undefined;
  promocode: string | undefined;
}

export const postApplicationCreateThunk = createAsyncThunk(
  "personalCabinet/postApplicationVaucher",
  async (data: postApplicationCreateThunk, { rejectWithValue }) => {
    try {
      const newTab = window.open("about:blank", "_blank");

      const res = await personalCabinetAPI.postApplication(data);
      if (res.data === null || /^\d+$/.test(res.data)) {
        if (newTab) {
          newTab.location.href = res.data;
        } else {
          window.location.href = res.data;
        }
      } else {
        window.location.reload();
      }
      return res.data;
    } catch (error: any) {
      if (error.response.data.title) {
        alertError(error.response.data.title);
      }
      return rejectWithValue(error.response.data.title);
    }
  }
);
